<template>
    <div class="edit-permission">
        <transition name="popup">
            <div class="popup-mask" v-if="isShowPopup == true" @click.self="clearFile()">
                <div class="popup-content">
                    <i class="close bi bi-x" @click="clearFile()"></i>
                    <!-- <div class="title">Manage Document Permissions</div> -->
                    <div class="title">{{ $t('manage-document-permission')}}</div>
                    <div class="content">
                        <div class="file-info">
                            <div class="file-detail">
                                <div class="file-detail-privacy">
                                    <!-- <h6>Set to private</h6> -->
                                    <h6>{{ $t('set-to-private') }}</h6>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="file-detail-privacy-input"
                                            v-model="privateFlag">
                                    </div>
                                </div>
                                <div v-if="!privateFlag">
                                    <div class="file-detail-permission">
                                        <!-- <h6>Add Permission</h6> -->
                                        <h6>{{ $t('add-permission') }}</h6>
                                        <div class="flex-space-between">
                                            <div>
                                                <select id="group-dept" v-model="selectedGroupDept">
                                                    <option v-for="group in groupList" :key="group.id">{{ group.dept }}
                                                    </option>
                                                </select>
                                                <select id="group-level" v-model="selectedGroupLevel">
                                                    <option v-for="level in availableGroupLevels" :key="level"
                                                        :value="level">
                                                        Level {{ level }}
                                                    </option>
                                                </select>
                                            </div>
                                            <button class="button add-button" @click="addPermission()">+</button>
                                        </div>
                                    </div>
                                    <div class="file-detail-perimission-list">
                                        <!-- <h6>Current Permission List:</h6> -->
                                        <h6>{{ $t('current-permission-list') }}：</h6>
                                        <ul>
                                            <li class="item" v-for="item in currentGroupList" :key="item.id">
                                                {{ item.dept }} Level {{ item.lvl }}
                                                <i class="bi bi-x-lg" @click="removePermission(item)"></i>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="button-list">
                                    <div class="button cancel-button" @click="clearFile()">
                                        <!-- Cancel -->
                                        {{ $t('cancel') }}
                                    </div>
                                    <div :class="{ 'button': true, 'disable-button': this.isUploading }"
                                        @click="saveFile(fileData)">
                                        <!-- Save -->
                                        {{ $t('save') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import api from '../../api/api.js';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
    name: 'editPermission',
    props: {
        doc: { type: Object, default: null },
    },
    data() {
        return {
            isShowPopup: true,

            doc_id: 0,
            privateFlag: false,
            groupList: [],
            currentGroupList: [],

            selectedGroupDept: null,
            selectedGroupLevel: null,

            url: '',
            pdfText: '',
            isGroupAiLoading: false,
            isUploading: false,
        };
    },
    methods: {
        addPermission() {
            if (this.selectedGroupDept && this.selectedGroupLevel) {
                const alreadyExists = this.currentGroupList.some(item =>
                    item.dept === this.selectedGroupDept && item.lvl === this.selectedGroupLevel
                );
                if (!alreadyExists) {
                    this.currentGroupList.push({
                        dept: this.selectedGroupDept,
                        lvl: this.selectedGroupLevel,
                    })
                }
            }
            this.removeSameDeptGroup();
        },

        removeSameDeptGroup() {
            this.currentGroupList = Object.values(this.currentGroupList.reduce((acc, item) => {
                acc[item.dept] = item;
                return acc;
            }, {}));
        },

        removePermission(item) {
            this.currentGroupList = this.currentGroupList.filter(i => i !== item);
        },

        clearFile() {
            this.doc_id = 0;
            this.currentGroupList = [];
            this.selectedGroupDept = '';
            this.selectedGroupLevel = '';
            this.privateFlag = false;

            this.closePopup();
        },

        closePopup() {
            this.isShowPopup = false;
            this.$emit('toggleEditPermissionPopup');
        },

        async saveFile() {
            if (!this.privateFlag && this.currentGroupList.length == 0) {
                alert("please add at least one permission group");
                return;
            }
            if (this.isUploading) {
                return;
            }
            this.isUploading = true;
            const data = {
                "doc_id": this.doc_id,
                "privateFlag": this.privateFlag ? 1 : 0,
                "fileAccessGroups": this.privateFlag ? [] : this.currentGroupList,
            };
            const response = await api.postEditAccessGroup(data);
            if (!response.error) {
                // this.$router.go(0);
                this.isUploading = false;
                this.$emit('refreshList');
                this.closePopup();
            } else {
                console.error(response);
            }
        },

        async getAceessGroupList() {
            const response = await api.getDocumentAceessGroupList();
            if (!response.error && response.data[0].attributes.extra.ricoh) {
                this.groupList = response.data[0].attributes.extra.ricoh.result;

                const uniqueGroups = this.groupList.reduce((acc, item) => {
                    const existingGroup = acc.find(group => group.dept === item.group_dept);
                    if (existingGroup) {
                        existingGroup.lvl.push(item.group_level);
                    } else {
                        acc.push({ dept: item.group_dept, lvl: [item.group_level] });
                    }
                    return acc;
                }, []);
                this.groupList = uniqueGroups;
            }
        },

        async aiGenerateAccessGroup(prompt = '', retry = 0) {
            if (this.isGroupAiLoading == true) {
                return;
            }
            this.isGroupAiLoading = true;
            let response;
            if (prompt === '') {
                response = await api.postAskAccessGroup("help me to generate some different access group. Reply me a json string only. The json should contain a 'dept' field and a 'lvl' field. The 'dept' field should be a string and the 'lvl' field should be a number. For example, the reply should be like this: [{\"dept\": \"HR\", \"lvl\": 1},{\"dept\": \"HR\", \"lvl\": 2},{\"dept\": \"IT\", \"lvl\": 1}]. The maximuim length of the array is 5. The maximuim lvl is 3. Each item should only has one department and one lvl. Reply me a json string only. Your reply should not contain any other text or information.");
            } else {
                response = await api.postAskAccessGroup(prompt);
            }
            this.isGroupAiLoading = false;
            let result;
            if (!response.error) {
                try {
                    const jsonString = response.data[0].attributes.extra.ricoh.result.content.match(/```json({.*})```|({.*})/s)[0];
                    result = JSON.parse("[" + jsonString + "]")[0];

                }
                catch (error) {
                    console.error('JSON parse error');
                }
            } else {
                console.error(response);
            }
            if (result) {
                const filterResult = this.groupList
                    .filter(item => result.access_departments.includes(item.dept) && item.lvl.includes(parseInt(result.access_level)))
                    .map(item => ({ dept: item.dept, lvl: result.access_level }));
                if (filterResult.length > 0) {
                    filterResult.forEach(newMatch => {
                        const alreadyExists = this.currentGroupList.some(item =>
                            item.dept === newMatch.dept && item.lvl === newMatch.lvl
                        );
                        if (!alreadyExists) {
                            this.currentGroupList.push(newMatch);
                        }
                    });
                    this.removeSameDeptGroup();
                } else {
                    if (retry < 3) {
                        console.log('No new matching access group found. Trying. Automatically regenerating ' + (retry + 1) + '...');
                        this.aiGenerateAccessGroup(prompt, retry + 1);
                    } else {
                        alert('No new matching access group found. You can try to generate again.');
                    }
                }
            }
        },

        async downloadAndReadFile() {
            if (this.url != '') {
                const response = await fetch(this.url);
                const arrayBuffer = await response.arrayBuffer();
                const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
                let textContent = '';
                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const content = await page.getTextContent();
                    content.items.forEach(item => {
                        textContent += item.str + ' ';
                    });
                }
                this.pdfText = textContent.trim();
                if (this.pdfText) {
                    this.aiGenerateAccessGroup(this.pdfText);
                } else {
                    console.error('PDF text is empty');
                }
            } else {
                console.error('URL is empty');
            }
        }
    },

    computed: {
        availableGroupLevels() {
            if (this.selectedGroupDept !== null) {
                const selectedGroup = this.groupList.find(
                    (group) => group.dept === this.selectedGroupDept
                );
                return selectedGroup ? selectedGroup.lvl : [];
            }
            return [];
        },
    },

    mounted() {
        this.getAceessGroupList();
    },

    watch: {
        doc: {
            handler(newVal) {
                this.doc_id = newVal.id;
                this.privateFlag = (newVal.doc_private_flag == 0) ? false : true;
                this.currentGroupList = JSON.parse(newVal.doc_groups_json);
                this.url = newVal.doc_oss_path;
            },
            deep: true,
            immediate: true
        }
    },
}
</script>

<style scoped>
.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}

.popup-mask .popup-content {
    position: absolute;
    width: 30%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 1000;
    border-radius: 30px;
    padding: 20px;
    min-width: 530px;
    max-height: 100%;
    overflow-y: scroll;
}

.popup-mask .popup-content::-webkit-scrollbar {
    display: none;
}

.popup-mask .popup-content .close {
    border-radius: 40px;
    width: 18px;
    font-size: 20px;
    float: right;
    color: gray;
    position: absolute;
    right: 23px;
    top: 13px;
    cursor: pointer;
}

.popup-mask .popup-content .title {
    margin: 20px 0;
    font-weight: bold;
    font-size: 15px;
}

.content .file-detail .upload-bar-preview {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: dodgerblue;
}

.content .file-detail .button {
    border-radius: 20px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    background-color: #F58A32;
    padding: 0px 10px;
    border: unset;
    width: 20%;
    margin: 5px 5px;
    line-height: 35px;
    font-weight: bold;
}

.content .file-detail .button:hover {
    background-color: #F05A27;
}

.content .file-detail .button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.content .file-detail .button.disable-button {
    background-color: darkgray;
    cursor: default;
}

.content .file-detail .button.cancel-button {
    background-color: transparent;
    color: gray;
}

.content .file-info {
    width: 100%;
    position: relative;
    padding: 0 10px;
}

.content .file-detail h6 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}

.content .file-detail .file-detail-privacy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 10px 0;
    padding: 10px;
    background-color: #F7F7F7;
    border-radius: 10px;
}

.content .file-detail .file-detail-privacy .form-switch .form-check-input {
    box-shadow: unset;
    border: 1px solid gray;
    background-color: gray;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e")
}

.content .file-detail .file-detail-privacy .form-switch .form-check-input:checked {
    border: 1px solid #F58A32;
    background-color: #F58A32;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e")
}

.content .file-detail .file-detail-permission {
    margin-top: 10px;
    text-align: left;
    font-size: 12px;
    background-color: #F7F7F7;
    padding: 10px;
    border-radius: 10px 10px 0 0;
}

.content .file-detail .file-detail-permission select {
    margin: 0 10px 0 0;
    min-width: 150px;
    border: 1px solid gray;
    border-radius: 15px;
    height: 1.5rem;
    padding: 0px 10px;
}

.content .file-detail .file-detail-permission .add-button {
    padding: 0;
    width: 115px;
    margin: 0;
    line-height: normal;
    border-radius: 20px;
}

.content .file-detail .file-detail-perimission-list {
    text-align: left;
    background-color: #F7F7F7;
    padding: 10px;
    border-radius: 0 0 10px 10px;
}

.content .file-detail .file-detail-perimission-list ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

.content .file-detail .file-detail-perimission-list ul li {
    list-style-type: none;
    background-color: peachpuff;
    margin: 0 5px 5px 5px;
    border-radius: 20px;
    padding: 0 15px;
    color: #F05A27;
    border: 1px solid #F05A27;
    font-size: 12px;
    font-weight: bold;
}

.content .file-detail .file-detail-perimission-list ul li i {
    color: #FFFFFF;
    cursor: pointer;
}

.content .file-detail .button-list {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.content .file-detail .ai-generate {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
}

.content .file-detail .ai-generate .ai-generate-button {
    border-radius: 20px;
    background-color: crimson;
    color: white;
    padding: 0 10px;
    width: 115px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
}

.content .file-detail .ai-generate .ai-generate-button:hover {
    background-color: firebrick;
}

.content .file-detail .ai-generate .ai-generate-button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.content .file-detail .ai-generate p {
    margin: 0;
    text-align: left;
    font-size: 12px;
    width: 60%;
}

.loading {
    position: relative;
    width: 20px;
    height: 20px;
}
</style>
<template>

  <body>
    <div v-if="loginStatus != null" id="home" class="container">
      <div class="row top-row">
        <div class="logo-col">
          <a href="#" @click="turnToPage('go', 0, true);"><img class="logo" src="../assets/logo_72.png"></a>
        </div>
        <div class="storage-bar">
          <div class="storage-bar-left">
            <!-- <div class="storage-total">Total used:</div> -->
            <div class="storage-total">{{ $t('space-used') }}：</div>
            <div class="storage-quantity">{{ formatBytes(storageUsed) }}</div>
          </div>
          <div class="storage-bar-middle">
            <div class="storage-progress-bar">
              <div class="storage-progress"
                :style="{ width: storageLimit === 0 ? '0%' : (Math.floor(storageUsed / storageLimit * 100)) + '%' }">
              </div>
              <div class="storage-progress-percentage">{{ formatBytes(storageLimit) }}({{ storageLimit === 0 ? 0 :
                (Math.floor(storageUsed / storageLimit * 100)) }})%</div>
            </div>
          </div>
          <div class="storage-bar-right"
            :style="{ visibility: (storageLimit !== 0 && (Math.floor(storageUsed / storageLimit * 100)) >= 90) ? 'visible' : 'hidden' }">
            <!-- <button @click="message = 'storage'; toggleContactUsPopup();">Upgrade</button> -->
            <button @click="message = $t('contact-storage'); toggleContactUsPopup();">{{ $t('upgrade') }}</button>
          </div>
        </div>
        <div class="search-bar">
          <!-- <input class="search-bar-input" placeholder="Search" v-model="searchText" @keyup.enter="searchByTitle"> -->
          <input class="search-bar-input" :placeholder=" $t('title-search') " v-model="searchText" @keyup.enter="searchByTitle">
          <i class="bi bi-search" @click="searchByTitle"></i><i class="bi bi-x-lg" @click="clearSearch"></i>
        </div>
        <div class="upload-bar">
          <!-- <div class="upload-bar-input" @click="toggleUploadPopup">Upload new documents</div> -->
          <div class="upload-bar-input" @click="toggleUploadPopup">{{ $t('upload-new') }}</div>
          <i class="bi bi-upload" @click="toggleUploadPopup"></i>
        </div>
        <div class="user-info-bar">
          <a :class="{ 'user-info': true, 'user-info-short': isManager }" @click="toggleUserInfoPopup">
            <div class="flex-space-around">
              <i class="bi bi-person"></i>
              <p>{{ userEmail }}</p>
            </div>
            <i class="bi bi-chevron-right"></i>
          </a>
          <div v-if="isManager" class="setting">
            <i class="bi bi-gear" @click="toggleAccessControlPopup"></i>
          </div>
          <userInfo :isShowPopup="isShowUserInfoPopup" :userEmail="userEmail" :userDept="userDept"
            :userLevel="userLevel" :userPreferLang="preferLang" @toggleUserInfoPopup="toggleUserInfoPopup"
            @toggleChangePasswordPopup="toggleChangePasswordPopup" @changeLang="changeLang"></userInfo>
        </div>
      </div>
      <div class="row main-content">
        <div class="col-8 left-content">
          <div class="row items-list scrollbar">
            <ul class="list-group">
              <div class="loading list-loading" v-if="isListLoading"></div>
              <li class="list-group-item" v-for="item in mediaList" :key="item.id">
                <documentItem :doc_id="item.id" :fileName=item.doc_filename :updateTime=item.last_update_time
                  :title=item.doc_title :content=item.doc_description :privacy="item.doc_private_flag"
                  :group="item.doc_groups_json" :url="item.doc_oss_path" :doc_uid="item.user_id" :uid="userId"
                  :size="item.doc_filesize" @changeDocId="changeDocId" @toggleEditMetaPopup="toggleEditMetaPopup"
                  @toggleEditPermissionPopup="toggleEditPermissionPopup"
                  @toggleDeleteConfirmPopup="toggleDeleteConfirmPopup"></documentItem>
              </li>
            </ul>
          </div>
          <div class="bottom-nav">
            <!-- <p>Total: {{ totalItems }} results</p> -->
            <p>{{ $t('total') }}: {{ totalItems }} {{ $t('result') }}</p>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-end">
                <li class="arrow-button">
                  <a href="#" @click="turnToPage('prev');"><i class="bi bi-chevron-left"></i></a>
                </li>
                <li v-for="n in 5" :key="n"><a v-if="n - 2 + currentPage <= totalPages && n - 2 + currentPage > 0"
                    href="#" @click="turnToPage('go', n - 3 + currentPage);"
                    :class="{ 'active-page': currentPage === n - 3 + currentPage }">{{ n - 2 + currentPage }}</a></li>
                <li class="arrow-button">
                  <a href="#" @click="turnToPage('next');"><i class="bi bi-chevron-right"></i></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="col-4">
          <div class="assist-chat-box info-col">
            <div class="assist-name">
              <!-- <span>DocuFinder Assist</span> -->
              <span>{{ $t('docu-finder-assist') }}</span>
              <button v-if="!isShowStylePopup" class="style-setting" @click="isShowStylePopup = !isShowStylePopup">
                <span>{{ $t('style-config') }}</span>
                <i class="bi bi-caret-right-fill"></i>
              </button>
              <button v-else class="style-setting active" @click="isShowStylePopup = !isShowStylePopup">
                <span>{{ $t('style-config') }}</span>
                <i class="bi bi-caret-down-fill"></i>
              </button>
            </div>
            <transition name="style-fade" mode="out-in">
              <div v-if="isShowStylePopup" class="style-setting-popup">
                <p>{{ $t('style-prompt') }}</p>
                <textarea rows="5" v-model="sysPrompt"
                  :placeholder=" $t('style-example') "></textarea>
                <div class="style-save">
                  <p>{{ $t('save-style') }}</p>
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" v-model="saveSysPrompt">
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade" mode="out-in">
              <div class="assist-default" v-show="!isOnChat">
                <img src="../assets/portrait.png" />
                <!-- <div class="assist-ask">Ask me for the document or context you need</div> -->
                <div class="assist-ask">{{ $t('ask-for-document') }}</div>
                <div v-for="suggestion in suggestionArray" :key="suggestion" >
                  <div class="quick-option" @click="quickOptionSearch(suggestion.suggest_question)" v-if="suggestion.suggest_question.trim().length > 0">
                    <i class="bi bi-question-circle"></i>
                    <div>{{ suggestion.suggest_question }}</div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade" mode="out-in">
              <div class="assist-chat" v-show="isOnChat">
                <div class="question-row">
                  <div ref="assistQuestion" class="question scrollbar-hidden">
                    <Markdown :source="questionContentCopy" />
                  </div>
                </div>
                <div class="answer">
                  <img src="../assets/portrait.png" />
                  <div class="answer-text">
                    <div class="loading" v-if="isLoading"></div>
                    <div class="assist-answer scrollbar" ref="assistAnswer">
                      <Markdown :source="answerSource" />
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <div class="ask-me-search">
              <div class="ask-me-search-bottom">
                <!-- <input type="text" placeholder="Ask Me Search" v-model="questionContent"> -->
                <input type="text" :placeholder=" $t('ask-me') " v-model="questionContent">
                <div><a @click="clearQuestion"><i class="bi bi-arrow-clockwise"></i></a><a @click="sendQuestion"><i
                      class="bi bi-search"></i></a></div>
              </div>
            </div>
            <div class="search-quota">
              <div>
                {{ $t('monthly-quota') }}：<span
                  :style="{ color: searchLimit !== 0 && (Math.floor((searchLimit - searchUsed) / searchLimit * 100)) <= 10 ? '#E75D38' : '#2784BB' }">{{
                    searchLimit - searchUsed }} / {{ searchLimit }}</span>
              </div>
              <div
                :style="{ visibility: (searchLimit !== 0 && (Math.floor((searchLimit - searchUsed) / searchLimit * 100)) <= 10) ? 'visible' : 'hidden' }">
                <div>
                  {{ $t('not-enough-searching-quota') }}
                </div>
                <div>
                  <button @click="message = $t('contact-search'); toggleContactUsPopup();">{{ $t('upgrade') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <uploadDocument v-if="isShowUploadPopup" :userEmail="userEmail" @toggleUploadPopup="toggleUploadPopup"
      @refreshList="getMediaList(currentPage)">
    </uploadDocument>
    <editMeta v-if="isShowEditMetaPopup" :doc="currentDoc" @toggleEditMetaPopup="toggleEditMetaPopup"
      @refreshList="getMediaList(currentPage)"></editMeta>
    <editPermission v-if="isShowEditPermissionPopup" :doc="currentDoc"
      @toggleEditPermissionPopup="toggleEditPermissionPopup" @refreshList="getMediaList(currentPage)"></editPermission>
    <deleteConfirm v-if="isShowDeleteConfirmPopup" :doc="currentDoc"
      @toggleDeleteConfirmPopup="toggleDeleteConfirmPopup" @refreshList="getMediaList(currentPage)"></deleteConfirm>
    <changePassword v-if="isShowChangePasswordPopup" @toggleChangePasswordPopup="toggleChangePasswordPopup">
    </changePassword>
    <accessControl v-if="isShowAccessControlPopup" @toggleAccessControlPopup="toggleAccessControlPopup"></accessControl>
    <contactUs v-if="isShowContactUsPopup" @toggleContactUsPopup="toggleContactUsPopup" :message="message"></contactUs>
  </body>
</template>

<script>
import documentItem from './documentItem.vue';
import uploadDocument from './pop-up/uploadDocument.vue';
import editMeta from './pop-up/editMeta.vue';
import editPermission from './pop-up/editPermission.vue';
import deleteConfirm from './pop-up/deleteConfirm.vue';
import userInfo from './pop-up/userInfo.vue';
import Markdown from 'vue3-markdown-it';
import changePassword from './pop-up/changePassword.vue';
import api from '../api/api.js';
import accessControl from './pop-up/accessControl.vue';
import contactUs from './pop-up/contactUs.vue';
import { SUPPORT_LOCALES, switchLocaleMessages } from '../i18n.js';

export default {
  name: 'HomePage',
  components: {
    documentItem,
    uploadDocument,
    editMeta,
    editPermission,
    deleteConfirm,
    userInfo,
    Markdown,
    changePassword,
    accessControl,
    contactUs
  },
  data() {
    return {
      totalItems: 0,
      mediaList: [],
      currentPage: 0,
      pageSize: 10,
      totalPages: 0,
      islogin: false,

      userEmail: '',
      userDept: '',
      userLevel: '',
      userId: 0,
      isManager: 0,
      companyId: '',
      preferLang: '',

      loginStatus: null,

      isShowUploadPopup: false,
      isShowEditMetaPopup: false,
      isShowEditPermissionPopup: false,
      isShowDeleteConfirmPopup: false,
      isShowUserInfoPopup: false,
      isShowChangePasswordPopup: false,
      isShowAccessControlPopup: false,
      isShowContactUsPopup: false,
      isShowStylePopup: false,
      message: '',

      currentDocId: 0,
      currentDoc: [],

      isOnChat: false,
      questionContent: '',
      questionContentCopy: '',
      answerContent: '',
      isLoading: false,
      isListLoading: false,
      answerSource: '',

      searchText: '',
      storageLimit: 0,
      storageUsed: 0,
      searchLimit: 0,
      searchUsed: 0,

      sysPrompt: '',
      saveSysPrompt: false,

      resultArray: [],
      suggestionArray: [],
    }
  },

  methods: {
    async getMediaList(pageNum) {
      this.currentPage = pageNum;
      this.mediaList = [];
      this.totalItems = 0;
      this.totalPages = 0;
      this.isListLoading = true;
      try {
        const response = await api.getDocumentList(pageNum, this.pageSize);
        if (!response.errors) {
          if (response.data[0].attributes.extra.ricoh) {
            this.mediaList = response.data[0].attributes.extra.ricoh.result.docList.data;
            this.totalItems = response.data[0].attributes.extra.ricoh.result.docList.total;
            this.totalPages = Math.ceil(this.totalItems / this.pageSize);
            this.suggestionArray = response.data[0].attributes.extra.ricoh.result.docList.suggestion;
            this.suggestionArray.forEach(suggestion => {
              suggestion.suggest_question_tc = suggestion.suggest_question;
              switch (this.preferLang) {
                case "en":
                  suggestion.suggest_question = suggestion.suggest_question_en;
                  break;
                case "tc":
                  suggestion.suggest_question = suggestion.suggest_question_tc;
                  break;
                case "sc":
                  suggestion.suggest_question = suggestion.suggest_question_sc;
                  break;
              }
            });
          } else {
            console.error("No Ricoh extra field");
          }
        } else {
          console.error("Fail to get response");
        }
        this.getCompanyQuotaPlan();
      } catch (error) {
        console.error("Fail to call api");
        console.error(error);
      }
      this.isListLoading = false;
    },

    getDocumentById(docId) {
      return this.mediaList.filter(i => i.id == docId)[0];
    },

    toggleUploadPopup() {
      this.isShowUploadPopup = !this.isShowUploadPopup;
    },

    toggleEditMetaPopup() {
      this.isShowEditMetaPopup = !this.isShowEditMetaPopup;
    },

    toggleEditPermissionPopup() {
      this.isShowEditPermissionPopup = !this.isShowEditPermissionPopup;
    },

    toggleDeleteConfirmPopup() {
      this.isShowDeleteConfirmPopup = !this.isShowDeleteConfirmPopup;
    },

    toggleUserInfoPopup() {
      this.isShowUserInfoPopup = !this.isShowUserInfoPopup;
    },

    toggleChangePasswordPopup() {
      this.isShowChangePasswordPopup = !this.isShowChangePasswordPopup;
    },

    toggleAccessControlPopup() {
      this.isShowAccessControlPopup = !this.isShowAccessControlPopup;
    },

    toggleContactUsPopup() {
      this.isShowContactUsPopup = !this.isShowContactUsPopup;
    },

    changeDocId(DocId) {
      this.currentDocId = DocId;
      this.currentDoc = this.getDocumentById(this.currentDocId);
    },

    logout() {
      api.logout();
      this.$router.push({ path: '/' });
    },

    getLoginInfo(user) {
      this.userEmail = user.attributes.email;
      this.userDept = user.attributes['doc-department'];
      this.userLevel = user.attributes['doc-level'];
      this.userId = user.attributes.id;

      let preferLang = user.attributes['ricoh-prefer-lang'];
      if (!SUPPORT_LOCALES.includes(preferLang)) {
        this.preferLang = 'en';
      } else {
        this.preferLang = preferLang;
      }

      this.isManager = user.attributes['extra'].is_manager;
      this.companyId = user.attributes['extra'].manager_company_id;
    },

    quickOptionSearch(question) {
      this.questionContent = question;
      this.sendQuestion();
    },

    sendQuestion() {
      if (this.isLoading || this.isListLoading) {
        return;
      }
      if (this.questionContent == '') {
        return;
      }
      this.getAssistAnswer(this.questionContent);
      this.isOnChat = true;
      //this.$refs.assistQuestion.textContent = this.questionContent.replace(/\n/g, '<br>');
      this.questionContentCopy = this.questionContent.replace(/\n/g, '  \n');
      this.questionContent = '';
      this.isLoading = true;
    },

    async searchByTitle() {
      if (this.isLoading || this.isListLoading) {
        return;
      }
      const response = await api.postSearchByTitle(this.searchText);
      if (!response.error) {
        this.isLoading = false;
        this.mediaList = response.data[0].attributes.extra.ricoh.result.collectionInfos;
        this.totalItems = this.mediaList.length;
        this.totalPages = 1;
      } else {
        console.error(response);
      }
    },

    clearSearch() {
      if (this.isLoading || this.isListLoading || !this.searchText) {
        return;
      }
      this.searchText = '';
      this.getMediaList(0);
    },

    clearQuestion() {
      if (this.isLoading || this.isListLoading) {
        return;
      }
      this.isOnChat = false;
      this.questionContent = '';
      this.answerContent = '';
      this.isLoading = false;
      this.getMediaList(0);
    },

    turnToPage(mode = 'go', pageNum = 0, force = false) {
      if (this.isLoading || this.isListLoading) {
        return;
      }
      if (mode == 'go') {
        if (this.currentPage == pageNum && !force) return;
        this.currentPage = pageNum;
        this.getMediaList(this.currentPage);
      } else if (mode == 'prev') {
        if (this.currentPage == 0) return;
        this.currentPage = Math.max(0, this.currentPage - 1);
        this.getMediaList(this.currentPage);
      } else if (mode == 'next') {
        if (this.currentPage == this.totalPages - 1) return;
        if (this.totalPages > 1) {
          this.currentPage = Math.min(this.totalPages - 1, this.currentPage + 1);
        } else {
          this.currentPage = 0;
        }
        this.getMediaList(this.currentPage);
      }
    },

    async getAssistAnswer(prompt) {
      // this.$refs.assistAnswer.textContent = '';
      this.answerSource = '';
      const response = await api.postSearchByPrompt(prompt, this.sysPrompt, this.saveSysPrompt);
      if (!response.error && response.data[0].attributes.extra.ricoh) {
        this.isLoading = false;
        this.answerContent = response.data[0].attributes.extra.ricoh.result.aiResponse;
        // this.$refs.assistAnswer.innerHTML = this.answerContent.replace(/\n/g, '<br>');
        this.answerSource = this.answerContent;
        // this.answerSource = this.answerContent.replace(/(?<=^)####\s(?!\s)/g, '##### ');
        // this.answerSource = this.answerSource.replace(/(?<=^)###\s(?!\s)/g, '#### ');
        this.mediaList = response.data[0].attributes.extra.ricoh.result.collectionInfos;
        this.totalItems = this.mediaList.length;
        this.totalPages = 1;
        this.getCompanyQuotaPlan();
      } else {
        console.error(response.error);
      }
    },

    async getCompanyQuotaPlan() {
      const response = await api.getCompanyQuotaPlan();
      if (!response.error) {
        const companyQuotaPlan = response.data[0].attributes.extra.ricoh.result.data;
        this.storageLimit = companyQuotaPlan.storage_limit;
        this.storageUsed = companyQuotaPlan.storage_used;
        this.searchLimit = companyQuotaPlan.search_period_limit;
        this.searchUsed = companyQuotaPlan.search_period_used;
      } else {
        console.error(response.error);
      }
    },


    formatBytes(bytes) {
      if (bytes === 0) return '0 KB';
      const k = 1024;
      const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },

    async changeLang(newLang) {
      await api.postSetUserPrefLang(newLang);

      this.preferLang = newLang
    },

    async switchLang() {
      // console.log(this.preferLang);
      await switchLocaleMessages(this.$i18n, this.preferLang);
      this.suggestionArray.forEach(suggestion => {
        switch (this.preferLang) {
          case "en":
            suggestion.suggest_question = suggestion.suggest_question_en;
            break;
          case "tc":
            suggestion.suggest_question = suggestion.suggest_question_tc;
            break;
          case "sc":
            suggestion.suggest_question = suggestion.suggest_question_sc;
            break;
        }
      });
      // console.log(this.$i18n);
    }
  },

  async mounted() {
    this.loginStatus = await api.loginStatusChecker();
    if (this.loginStatus == null) {
      this.$router.push({ path: '/' })
    } else {
      this.getLoginInfo(this.loginStatus);
    }

    this.getMediaList(0);
    this.getCompanyQuotaPlan();
  },

  watch: {
    'preferLang'(newVal, oldVal) {
        if (newVal !== oldVal) {
          // console.log(this.$i18n);
          // const i18n = setupI18n({locale: newVal});
          // switchLocaleMessages(this.$i18n, newVal);
          this.switchLang();
          // this.$i18n = i18n;
        }
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.min.css";

body {
  background: #FFFFFF;
  position: relative;
  cursor: default;
}

#home {
  background: linear-gradient(to left, #71AAE7, #FA9539, #C4E2FE);
  min-height: 100vh;
  padding: 15px 5% 0 5%;
  max-width: unset;
  font-size: 14px;
  color: #575B5E;
}

.popup-mask {
  position: absolute;
  z-index: 100;
}

#home .top-row {
  position: relative;
  z-index: 2;
}

#home .main-content .left-content {
  background-color: #FFFFFF;
  padding: 20px 35px 0px 35px;
  border-radius: 20px;
  height: 90vh;
}

.flex-space-around {
  display: flex;
  justify-content: space-around;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.logo {
  height: 50px;
  position: initial;
}

.upload-bar {
  width: 21%;
  display: flex;
  justify-content: flex-end;
  padding: 0px 3px;
  align-items: center;
}

.upload-bar i {
  position: absolute;
  transform: translate(-25px, 3px);
  color: gray;
  cursor: pointer;
}

.upload-bar-input {
  width: 100%;
  height: 40px;
  border-radius: 30px;
  padding-left: 20px;
  text-align: left;
  align-content: center;
  background-color: #FFFFFF;
  color: #8D8D91;
  margin-bottom: 0;
  cursor: pointer;
}

.user-info-bar {
  width: 33%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.user-info-bar .user-info {
  display: flex;
  background-color: #FFFFFF;
  border-radius: 25px;
  color: gray;
  justify-content: space-between;
  padding: 0px 20px;
  height: 2.5rem;
  margin: 4px 0px;
  cursor: pointer;
  text-decoration: none;
  min-width: -moz-fit-content;
  min-width: fit-content;
  width: 100%;
  align-items: center;
}

.user-info-bar .user-info.user-info-short {
  width: 85%;
}

.user-info-bar .user-info div i {
  border-radius: 25px;
  background: linear-gradient(45deg, #29A4A9, #F58A32);
  color: #FFFFFF;
  height: fit-content;
  padding: 0px 4px;
  margin-right: 5px;
}

.user-info-bar .user-info div p {
  margin: 0;
}

.user-info-bar .setting {
  margin: 0 auto;
  color: #FFFFFF;
  font-size: 35px;
  cursor: pointer;
}

.list-group-item {
  background-color: unset;
  margin: 0;
  padding: 0;
  border: unset;
  display: table;
}

.items-list {
  height: 90%;
  overflow-y: scroll;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #DEDEDE;
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #5492CA;
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-thumb:hover,
.scrollbar::-webkit-scrollbar-thumb:active {
  background: #0F89CA;
  border-radius: 10px;
}

.bottom-nav {
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
  align-items: flex-end;
  margin-top: 10px;
  color: #404142;
}

.bottom-nav .pagination {
  display: inline-flex;
}

.bottom-nav .pagination a {
  color: #8D8D91;
  float: left;
  padding: 3px 9px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #AAACAF;
  margin: 0 3px;
  border-radius: 5px;
  background-color: white;
}

.bottom-nav .pagination a.active-page {
  background-color: #5492CA;
  border: 1px solid #5492CA;
  color: #FFFFFF;
}

.bottom-nav .pagination .arrow-button a {
  border: unset;
  background-color: unset;
  padding: 3px 7px;
}

.bottom-nav .pagination a:hover {
  border: 1px solid #AAACAF;
  background-color: #DDDDDD;
}

.main-content {
  margin-top: 15px;
  padding: 0;
}

.info-col {
  position: relative;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  border: 1px solid #afafaf;
  min-height: 90vh;
  min-width: fit-content;
  text-align: -webkit-center;
}

.assist-name {
  background: linear-gradient(to left, #71AAE7, #FA9539, #C4E2FE);
  border-radius: 20px 20px 0 0;
  padding: 15px 20px 10px 20px;
  font-weight: bold;
  color: #FFFFFF;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.style-setting {
  width: 120px;
  background-color: #FFFFFF;
  border: unset;
  border-radius: 30px;
  color: #666666;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
}

.style-setting i {
  color: #929292;
}

.style-setting.active {
  color: #5A9AD8;
}

.style-setting-popup {
  position: absolute;
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 0px 0px 20px 20px;
  padding: 10px 25px;
  text-align: left;
  color: #A0A0A0;
}

.style-setting-popup textarea {
  background-color: #F2F2F2;
  border: unset;
  padding: 10px;
  width: 100%;
  overflow-y: scroll;
  resize: none;
  outline: unset;
}

.style-setting-popup textarea::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.style-setting-popup p {
  margin: 5px 0;
}

.style-setting-popup .style-save {
  display: flex;
  align-items: center;
}

.style-save .form-switch {
  padding: 0 9px;
  margin: 0;
}

.style-save .form-check-input {
  height: 25px;
  width: 45px;
  margin: 0;
  box-shadow: unset;
  border: 1px solid gray;
  background-color: gray;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e");
}

.style-save .form-check-input:checked {
  border: 1px solid #F58A32;
  background-color: #F58A32;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e");
}

.assist-ask {
  margin: 50px 0 10px 0;
  font-weight: bold;
  color: gray;
}

.quick-option {
  background-color: rgba(255, 255, 255, 0.7);
  color: #8D8D91;
  border: 1px solid darkgray;
  border-radius: 10px;
  margin: 3px 0;
  width: 90%;
  padding: 8px 20px;
  text-align: left;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.quick-option i {
  color: #EE8443;
  margin-right: 5px;
}

.ask-me-search {
  margin-top: 23vh;
  margin-bottom: 10px;
}

.ask-me-search .ask-me-search-upper {
  width: 80%;
  border: 1px solid darkgray;
  border-bottom: unset;
}

.ask-me-search .ask-me-search-upper textarea {
  width: 100%;
  resize: none;
  border: unset;
  background: transparent;
  align-content: end;
}

.ask-me-search .ask-me-search-upper textarea:focus {
  outline: none;
}

.ask-me-search .ask-me-search-bottom {
  width: 80%;
  border-bottom: 1px solid #AAACAF;
  padding: 0 15px 5px 15px;
  display: flex;
  justify-content: space-between;
}

.ask-me-search .ask-me-search-bottom input {
  color: #575B5E;
  background-color: transparent;
  border: unset;
  outline: unset;
  width: 85%;
}

.ask-me-search .ask-me-search-bottom a {
  cursor: pointer;
  margin-left: 10px;
}

.assist-chat {
  margin-top: 25px;
}

.assist-chat .question-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.assist-chat .question-row i {
  color: white;
  border-radius: 25px;
  background-color: red;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0px 6px;
  font-size: 24px;
}

.assist-chat .question {
  border: 1px solid #F58A32;
  border-radius: 20px 20px 0 20px;
  width: 90%;
  padding: 10px 5px;
  color: gray;
  background-color: whitesmoke;
  margin: 40px 0;
  box-shadow: 2px 3px 5px lightgray;
  overflow-y: scroll;
  max-height: 8vh;
  text-align: left;
}

.assist-chat .question p {
  margin: 0 5px;
}

.assist-chat .answer {
  display: flex;
  justify-content: space-around;
  width: 92%;
  align-items: flex-end;
}

.assist-chat .answer img {
  width: 6%;
  height: 6%;
}

.assist-chat .answer .answer-text {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 15px 15px 15px 0;
  padding: 10px 2px 10px 5px;
  color: gray;
  background-color: whitesmoke;
  box-shadow: 2px 3px 5px lightgray;
  margin-left: 2px;
}

.assist-chat .answer .answer-text .assist-answer {
  text-align: left;
  max-height: 35vh;
  overflow-y: scroll;
}

#home .main-content .assist-chat-box .assist-default {
  margin-top: 25px;
}

#home .main-content .assist-chat-box .assist-default img {
  width: 175px;
}

.loading {
  position: relative;
  width: 30px;
  height: 30px;
  border: 2px solid #000;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-right-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.2);
  border-radius: 100%;

  animation: circle infinite 0.75s linear;
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fade-enter-active {
  transition: all 1s;
}

.fade-enter-from {
  opacity: 0;
  transform: translateY(-20px);
}

.style-fade-enter-active,
.style-fade-leave-active {
  transition: all 0.5s;
}

.style-fade-enter-from,
.style-fade-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}

.list-loading {
  z-index: 1;
  left: 45%;
  top: 40%;
}

#home .storage-bar {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
}

#home .storage-bar .storage-bar-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#home .storage-bar .storage-bar-left .storage-total {
  font-size: 12px;
}

#home .storage-bar .storage-bar-left .storage-quantity {
  font-weight: bold;
}

#home .storage-bar .storage-bar-middle {
  width: 50%;
}

#home .storage-bar .storage-bar-right {
  text-align: right;
}

#home .storage-bar .storage-bar-right button {
  padding: 0px 10px;
  background-color: #F7BA90;
  color: #E75D38;
  border: 1px solid #E75D38;
  border-radius: 25px;
  font-weight: bold;
  font-size: 12px;
}

#home .storage-bar .storage-bar-middle .storage-progress-bar {
  width: 100%;
  height: 20px;
  background-color: #FFFFFF;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  padding: 3px 4px;
}

#home .storage-bar .storage-bar-middle .storage-progress {
  height: 100%;
  border-radius: 20px;
  background-color: #B4D7F1;
  width: 0%;
  /* 根据需要调整进度百分比 */
  transition: width 0.5s ease;
}

#home .storage-bar .storage-bar-middle .storage-progress-bar .storage-progress-percentage {
  position: absolute;
  top: 1px;
  right: 10px;
  font-size: 12px;
}

#home .search-bar {
  width: 21%;
  display: flex;
  justify-content: flex-end;
  padding: 0px 3px;
  align-items: center;
}

#home .search-bar i {
  position: absolute;
  color: gray;
  cursor: pointer;
}

#home .search-bar .bi-search {
  transform: translate(-15px, 3px);
}

#home .search-bar .bi-x-lg {
  transform: translate(-35px, 3px);
}

#home .search-bar .search-bar-input {
  border: unset;
  outline: unset;
  width: 100%;
  height: 40px;
  border-radius: 30px;
  padding-left: 20px;
  text-align: left;
  align-content: center;
  background-color: #FFFFFF;
  color: #8D8D91;
  margin-bottom: 0;
}

#home .logo-col {
  width: 5%;
}

#home .main-content .info-col .search-quota {
  color: #2784BB;
  font-weight: bold;
}

#home .main-content .info-col .search-quota button {
  padding: 0px 10px;
  background-color: #F7BA90;
  color: #E75D38;
  border: 1px solid #E75D38;
  border-radius: 25px;
  font-weight: bold;
  font-size: 12px;
}
</style>
